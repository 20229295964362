cs.ns("app.ui.composite.login")
app.ui.composite.login.view = cs.clazz({
    extend:   app.ui.composite.abstract.modal.view,
    dynamics: {
        markupName: "appLogin"
    },
    protos:   {

        focus () {
            let focusField = cs(this).value('data:username') === '' ? this.vue.$refs.usernameInput : this.vue.$refs.passwordInput
            focusField.focus()
            focusField.select()
        }
    }
})